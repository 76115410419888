import { request, proRequest } from '@/utils/request.js'

// 获取用户积分记录
export function getCurrentIntegral(id) {
    return request.get(`/users/${id}/point_records`)
}

// 获取用户积分
export function getIntegral(id) {
    return request.get(`/users/${id}/points`)
}