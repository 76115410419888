<template>
  <div class="p-20 bg-white rounded">
    <div
      class="mb-40 p-20 relative flex justify-center bg-cover text-white"
      style="height: 168px"
      :style="{ backgroundImage: `url(${bg_url})` }"
    >
      <div class="absolute left-20 top-20 text-xs">积分规则</div>
      <div class="pt-10 flex items-center flex-col">
        <div class="text-sm mb-10 opacity-50">我的星球贝</div>
        <div class="font-bold mb-20">1200</div>
        <div
          style="width: 88px; border-radius: 20px"
          class="bg-white text-primary text-center leading-10 h-40 text-sm"
        >
          前往使用
        </div>
      </div>
    </div>
    <div>
      <div class="text-left font-bold mb-10">积分明细</div>
      <div>
        <div class="h-12 flex items-center">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bg_url from "@/assets/images/integral-bg.png";
import { getCurrentIntegral, getIntegral } from "@/api/integral";
export default {
  data() {
    return {
      bg_url,
      total: 0,
      list: [],
    };
  },
  computed: {
    company() {
      return this.$store.state.userInfo.id;
    },
  },
  created() {
    this.doGetCurrentIntegral(this.company);
    this.doGetIntegral(this.company);
  },
  methods: {
    doGetCurrentIntegral(id) {
        console.log(1);
      this.getCurrentIntegral(id);
    },
    doGetIntegral(id) {
      this.getIntegral(id);
    },
    getCurrentIntegral() {
      getCurrentIntegral()
        .then((res) => {
          console.log(res);
        })
        .catch(() => {
          this.$message.error("获取用户积分明细失败");
        });
    },
    getIntegral() {
      getIntegral()
        .then((res) => {
          console.log(res);
        })
        .catch(() => {
          this.$message.error("获取用户积分失败");
        });
    },
  },
};
</script>